export const options = [
  {
    label: 'id',
    prop: 'Id',
    width: 80
  },
  {
    label: '用户id',
    prop: 'UserId',
    width: 80
  },
  {
    label: '用户钱包地址',
    prop: 'UserAddress',
    width: 200
  },
  {
    label: '金额',
    prop: 'Amount'
  },
  {
    label: '操作类型',
    prop: 'OperationType',
    width: 120
  },
  {
    label: '货币类型',
    prop: 'CurrencyType'
  },
  {
    label: '操作状态',
    prop: 'OperationStatus'
  },
  {
    label: '创建时间',
    prop: 'CreatedAt',
    width: 80
  },
  {
    label: '操作',
    prop: 'action'
  }
]
