import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots } from "vue";
import { Link, Search } from '@element-plus/icons-vue';
import { ref } from 'vue';
import { records } from '@/api/fund_record';
import { options } from './options';
export default {
  __name: 'index',
  setup(__props) {
    const queryForm = ref({
      user_address: '',
      page: 1,
      page_size: 15
    });
    const tableData = ref([]);
    const total = ref(0);
    const iniInRecords = async () => {
      const res = await records(queryForm.value);
      total.value = res.pager.total_rows;
      tableData.value = res.list;
    };
    iniInRecords();
    const handleSizeChange = pageSize => {
      queryForm.value.page = 1;
      queryForm.value.page_size = pageSize;
      iniInRecords();
    };
    const handleCurrentChange = pageNum => {
      queryForm.value.page = pageNum;
      iniInRecords();
    };
    const redirectToLink = TradeHash => {
      window.open(`https://bscscan.com/tx/${TradeHash}`, '_blank');
    };
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_tag = _resolveComponent("el-tag");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_el_pagination = _resolveComponent("el-pagination");
      const _component_el_card = _resolveComponent("el-card");
      return _openBlock(), _createBlock(_component_el_card, null, {
        default: _withCtx(() => [_createVNode(_component_el_row, {
          gutter: 20,
          class: "header"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            span: 7
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              placeholder: _ctx.$t('table.placeholder_user_address'),
              clearable: "",
              modelValue: queryForm.value.user_address,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => queryForm.value.user_address = $event)
            }, null, 8, ["placeholder", "modelValue"])]),
            _: 1
          }), _createVNode(_component_el_button, {
            type: "primary",
            icon: _unref(Search),
            onClick: iniInRecords
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('table.search')), 1)]),
            _: 1
          }, 8, ["icon"])]),
          _: 1
        }), _createVNode(_component_el_table, {
          data: tableData.value,
          stripe: "",
          style: {
            "width": "100%"
          }
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(options), (item, index) => {
            return _openBlock(), _createElementBlock(_Fragment, null, [!item.hide ? (_openBlock(), _createBlock(_component_el_table_column, {
              width: item.width,
              prop: item.prop,
              label: item.label,
              key: index
            }, _createSlots({
              _: 2
            }, [item.prop === 'OperationStatus' ? {
              name: "default",
              fn: _withCtx(({
                row
              }) => [row.OperationStatus === 0 ? (_openBlock(), _createBlock(_component_el_tag, {
                key: 0
              }, {
                default: _withCtx(() => [_createTextVNode("待处理")]),
                _: 1
              })) : row.OperationStatus === 1 ? (_openBlock(), _createBlock(_component_el_tag, {
                key: 1,
                class: "ml-2",
                type: "success"
              }, {
                default: _withCtx(() => [_createTextVNode("成功")]),
                _: 1
              })) : row.OperationStatus === 2 ? (_openBlock(), _createBlock(_component_el_tag, {
                key: 2,
                class: "ml-2",
                type: "danger"
              }, {
                default: _withCtx(() => [_createTextVNode("失败")]),
                _: 1
              })) : row.OperationStatus === 3 ? (_openBlock(), _createBlock(_component_el_tag, {
                key: 3,
                class: "ml-2",
                type: "warning"
              }, {
                default: _withCtx(() => [_createTextVNode("提现中")]),
                _: 1
              })) : row.OperationStatus === 4 ? (_openBlock(), _createBlock(_component_el_tag, {
                key: 4,
                class: "ml-2",
                type: "danger"
              }, {
                default: _withCtx(() => [_createTextVNode("已拒绝")]),
                _: 1
              })) : row.OperationStatus === 5 ? (_openBlock(), _createBlock(_component_el_tag, {
                key: 5,
                class: "ml-2",
                type: "success"
              }, {
                default: _withCtx(() => [_createTextVNode("提现成功")]),
                _: 1
              })) : row.OperationStatus === 6 ? (_openBlock(), _createBlock(_component_el_tag, {
                key: 6,
                class: "ml-2",
                type: "success"
              }, {
                default: _withCtx(() => [_createTextVNode("提现失败")]),
                _: 1
              })) : _createCommentVNode("", true)]),
              key: "0"
            } : item.prop === 'OperationType' ? {
              name: "default",
              fn: _withCtx(({
                row
              }) => [row.OperationType === 1 ? (_openBlock(), _createBlock(_component_el_tag, {
                key: 0,
                class: "ml-2",
                type: "info"
              }, {
                default: _withCtx(() => [_createTextVNode("充值")]),
                _: 1
              })) : row.OperationType === 2 ? (_openBlock(), _createBlock(_component_el_tag, {
                key: 1,
                class: "ml-2",
                type: "info"
              }, {
                default: _withCtx(() => [_createTextVNode("提现")]),
                _: 1
              })) : row.OperationType === 3 ? (_openBlock(), _createBlock(_component_el_tag, {
                key: 2,
                class: "ml-2",
                type: "info"
              }, {
                default: _withCtx(() => [_createTextVNode("购买机器人")]),
                _: 1
              })) : _createCommentVNode("", true)]),
              key: "1"
            } : item.prop === 'action' ? {
              name: "default",
              fn: _withCtx(({
                row
              }) => [_createVNode(_component_el_button, {
                type: "primary",
                size: "small",
                icon: _unref(Link),
                onClick: $event => redirectToLink(row.TransactionHash)
              }, {
                default: _withCtx(() => [_createTextVNode("查看详情")]),
                _: 2
              }, 1032, ["icon", "onClick"])]),
              key: "2"
            } : undefined]), 1032, ["width", "prop", "label"])) : _createCommentVNode("", true)], 64);
          }), 256))]),
          _: 1
        }, 8, ["data"]), _createVNode(_component_el_pagination, {
          "current-page": queryForm.value.page,
          "onUpdate:currentPage": _cache[1] || (_cache[1] = $event => queryForm.value.page = $event),
          "page-size": queryForm.value.page_size,
          "onUpdate:pageSize": _cache[2] || (_cache[2] = $event => queryForm.value.page_size = $event),
          "page-sizes": [15, 20, 30, 40],
          small: _ctx.small,
          disabled: _ctx.disabled,
          background: _ctx.background,
          layout: "total, sizes, prev, pager, next, jumper",
          total: total.value,
          onSizeChange: handleSizeChange,
          onCurrentChange: handleCurrentChange
        }, null, 8, ["current-page", "page-size", "small", "disabled", "background", "total"])]),
        _: 1
      });
    };
  }
};